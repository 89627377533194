<template>
	<v-navigation-drawer
		v-if="!$vuetify.breakpoint.smAndDown"
		class="q-left-drawer"
		v-model="drawer"
		:expand-on-hover="!alwaysVisible || !toggle"
		:mini-variant.sync="isMini"
		app
		clipped
		permanent
		disable-resize-watcher
		hide-overlay
		:width="300"
		@transitionend="collapse"
	>
		<div :style="{ ...padding }" class="q-left-drawer__navigation-list-wrapper">
			<div class="q-left-drawer__navigation-list-scroller with-nav-scroll">
				<MainNavigationList
					ref="navigation_list"
					class="q-left-drawer__navigation-list"
					:mini="isMini"
				/>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import MainNavigationList from './MainNavigationList.vue'

const TOGGLE_LOCAL_STORAGE_KEY = 'q-sidebar-toggle'

const initialToggleValue = window.localStorage.getItem(TOGGLE_LOCAL_STORAGE_KEY) === 'true'

export default {
	components: {
        MainNavigationList,
    },
	props: {
		value: { type: Boolean, default: true },
		padding: { type: Object, default: () => ({}) },
		alwaysVisible: { type: Boolean, default: true },
	},
    data () {
        return {
            toggle: true,
            isMini: false,
        }
    },
	created () {
		this.toggle = this.alwaysVisible ? true : initialToggleValue
		this.isMini = this.alwaysVisible
			? false
			: initialToggleValue ? false : true
	},
	computed: {
		drawer: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
	},
    watch: {
		toggle (newValue) {
			window.localStorage.setItem(TOGGLE_LOCAL_STORAGE_KEY, newValue)
		},
    },
    methods: {
        collapse () {
            if (this.toggle) { return }
            if (this.isMini) {
                this.$refs.navigation_list.collapse()
            }
        },
	},
}
</script>

<style lang="scss">
.q-left-drawer {
	&__navigation-list-wrapper {
		width: var(--app-left-drawer-width);
		background-color: var(--app-color-primary);
		height: 100vh;
		display: flex;
		flex-direction: column;
	}

	&__navigation-list-scroller {
		flex: 1 1 auto;
	}

	// &__navigation-list {
	// 	width: calc(var(--app-left-drawer-width) - 12px);
	// }
}
</style>
