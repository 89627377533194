<template>
    <div>
        <!-- This is a dummy element to help position the tour step for the main navigation menu. //-->
        <div id="v-step-4" style="position: absolute; left: -5px; top: 150px"></div>
        <v-row justify="center" align="stretch">
            <v-col cols="12" id="v-step-5">
                <v-card class="pa-5" elevation="3">
                    <v-row justify="center">
                        <v-col cols="12" class="d-flex align-content-center flex-wrap px-5" style="position: relative">
                            <q-page-slider slug="ccs-homepage-slider"></q-page-slider>
                            <v-btn to="/cms/editor/1334" small light fab absolute top right v-if="hasPermission(['manage:Content'])">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <!-- Stats -->
        <v-row wrap justify="center" align="stretch" v-if="notRole('UnlicensedAgent')">
            <v-col cols="12">
                <v-card class="mx-auto ma-1" elevation="3" height="100%">
                    <v-col cols="12" class="indigo pa-1" id="v-step-6">
                        <p class="q-display-3 pl-5 pt-3 white--text">
                            My Stats
                            <v-btn icon to="/my_stats/scorecard/new_business">
                                <v-icon class="white--text">fas fa-arrow-circle-right</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <score-cards-new-business :show-submenu="false" :allowAgentLookup="false"></score-cards-new-business>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay opacity=".8" :value="!data_loaded">
            Loading...
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import QContestList from "@/components/lists/QContestList.vue";
import QNewsList from "@/components/lists/QNewsList.vue";

import QPageSlider from "@/components/utils/QPageSlider.vue";
import QLeaderboardExplorer from "@/views/MyStats/QLeaderboardExplorer.vue";
import ScoreCardsNewBusiness from "@/views/MyStats/ScoreCards_NewBusiness.vue";

export default {
    name: "DashboardCss",

    components: {
        QContestList,
        QNewsList,
        QPageSlider,

        QLeaderboardExplorer,
        ScoreCardsNewBusiness,
    },

    props: {
        to: {
            type: String,
            default: null,
        },
        baseUrl: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            message: `Your last logon was ${new Date().toLocaleString()}`,
            counter: 0,
            sheet: false,
            data_loaded: true,
        };
    },

    mounted() {
        // if the user has not already seen this tour it will automatically start.
        this.maybeAutoStartDemo("DemoDashboard");
    },

    computed: {
        submenu() {
            const submenu = [
                { label: "Help", to: "/support" },
                {
                    label: "Start Demo",
                    fun() {
                        this.startDemo();
                    },
                },
            ];
            return submenu;
        },
    },

    methods: {
        // starts the demo tour when the option in the ellipsis menu is clicked.
        startDemo() {
            this.$tours.DemoDashboard.start();
        },

        goTo(target) {
            if (target.anchor) {
                if (!this.isBase) {
                    this.$router.push(this.baseUrl + target.anchor);
                } else {
                    this.$vuetify.goTo(target.anchor, { easing: "easeInOutCubic", offset: 80 });
                }
            }
            if (target.to) {
                this.$router.push(target.to);
            }
            if (target.href) {
                window.open(target.href, "_blank");
            }
            if (target.fun) {
                target.fun();
            }
        },
    },
};

</script>
<style>
h4 {
    margin-bottom: 10px;
}

h1.with-short-rule::after,
h2.with-short-rule::after,
h3.with-short-rule::after,
h4.with-short-rule::after,
h5.with-short-rule::after {
    padding: 0.2em;
    display: block;
    width: 6%;
    height: 1px;
    content: " ";
    background-color: #daf3f0;
    margin: 4px 0 8px 0;
}

</style>
