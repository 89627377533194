const routes = [{
        path: '/hub-pages',
        name: 'HubPages',
        component: () => import( /* webpackChunkName: "hubPages" */ '../modules/HubPages/views/HubPages.vue'),
        meta: {
            permission: ['manage:Content']
        },
    }, {
        path: '/hub-page/:slug',
        name: 'HubPage',
        component: () => import( /* webpackChunkName: "hubPage" */ '../modules/HubPages/views/HubPage.vue'),
    }, {
        path: '/hub-page-audit/:pageId',
        name: 'HubPageAudit',
        component: () => import( /* webpackChunkName: "hubPageAudit" */ '../modules/HubPages/views/HubPageAudit.vue'),
    },
]

export default routes
