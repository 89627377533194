import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import amplitude from './plugins/amplitude';
import glia from './plugins/glia';
import intercom from './plugins/intercom';
import vuetify from './plugins/vuetify';
import VueTour from 'vue-tour'

import './sass/general.scss';
require('vue-tour/dist/vue-tour.css')

import GlobalMixins from './Mixins'
// Import the Auth0 configuration
import { Auth0Plugin } from "./auth";

Vue.mixin(GlobalMixins);

Vue.config.productionTip = false

import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import mapInit from 'highcharts/modules/map'
import bulletInit from 'highcharts/modules/bullet'
import mapData from '@highcharts/map-collection/countries/us/us-all-all.geo.json'
import tick_positioner from "@/components/utils/tick_positioner.js";
mapInit(Highcharts);
bulletInit(Highcharts);
Highcharts.maps['counties'] = mapData
tick_positioner(Highcharts);
Vue.use(HighchartsVue)



// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl ?
            appState.targetUrl :
            window.location.pathname
        );
    }
});

// Import Copy To Clipboard function
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);
Vue.use(VueTour)
Vue.use(amplitude)
Vue.use(intercom)
Vue.use(glia)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
