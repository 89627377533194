<template>
	<v-card class="pa-0 elevation-0 scorecard-card" height="100%">
        <div class="pa-1 pt-2 title-head" :class="cardStyle">
            {{ title }}
        </div>
        <div class="pa-3 body-stat" :class="cardStyle">
			<div class="note-card-content">
				<div>{{ cardNote }}</div>
				<v-btn text class="footer-btn" :ripple="false" @click="goToPerformDash">
					<div class="footer-btn__content justify-center">
						<v-icon color="white">icon-q-right-arrow</v-icon>
					</div>
				</v-btn>
			</div>
        </div>

        <div v-if="!hideGoal" class="footer-progress" :class="cardStyle" />

        <div class="footer-expand">
			<button class="footer-btn elevation-2" @click="goToPerformDash">
				<div class="footer-btn__content">
					<div class="footer-btn__label">{{ btnLabel }}</div>
					<v-icon class="footer-btn__icon" color="#0000008a">icon-q-right-arrow</v-icon>
				</div>
			</button>
        </div>
    </v-card>
</template>

<script>
export default {
	props: {
		title: { type: String, default: 'Close Ratio' },
		cardStyle: { type: String, default: 'production' },
		hideGoal: { type: Boolean, default: false },
	},
	data () {
		return {
			btnLabel: 'Close\u00A0Ratio in\u00A0PerformDash',
			cardNote: 'Close Ratios are now available in PerformDash',
		}
	},
	methods: {
		goToPerformDash () {
			this.$router.push('/perform-dash')
		},
	},
}
</script>

<style lang="scss" scoped>
.scorecard-card {
	display: flex;
	flex-direction: column;
}

.body-stat {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
}

.note-card-content {
	max-width: 8.5rem;
	font-size: 16px;
	text-align: justify;
}

.footer-btn {
	width: 100%;
	max-width: 100%;
	height: 48px !important;
	padding: 0 24px !important;
	font-size: 14px;
	line-height: 1.25;
	font-weight: 300;
	text-transform: none;
	letter-spacing: normal;
	border-radius: 4px;

	.footer-btn__content {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.footer-btn__label {
		text-align: left;
		flex-grow: 1;
		flex-shrink: 1;
	}

	.footer-btn__icon {
		margin-left: 0.5rem;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
</style>
