const createGliaScript = () => {
    const newScriptElement = document.createElement('script');
    newScriptElement.type = 'text/javascript';
    newScriptElement.async = true;
    newScriptElement.src = `https://api.glia.com/salemove_integration.js`;
    const firstScriptElement = document.getElementsByTagName('script')[0];
    firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement);

	return newScriptElement;
}

export default {
    install(app) {
		let script

		const makeScript = () => {
			script = createGliaScript();
		}

        app.prototype.$glia = {
            initialize() {
				if (document.readyState === 'complete') {
					makeScript();
				} else if (window.attachEvent) {
					window.attachEvent('onload', makeScript);
				} else {
					window.addEventListener('load', makeScript, false);
				}
            },
			boot() {
				if (!window.sm) {
					script.addEventListener('load', () => {
						this.boot()
					})
					return
				}

				console.log('glia booting')
				const sm = window.sm;
				sm.getApi({ version: 'v1' })
					.then((glia) => {
						glia.updateInformation({
							name: this.user.name,
							email: this.user.email,
							phone: this.user.Agent.AgentPhone,
							customAttributes: {
								agentcode: this.user.AgentCode,
								show_glia: this.hasPermission("agent:ShowGlia") ? "true" : "false"
							}
						})
						.catch(function (error) {
							console.log(error)
							if (error.cause == glia.ERRORS.NETWORK_TIMEOUT) {
		
							}
						});
					})
					.catch((error) => {
						console.log(error)
					})
			}
        }
    },
}