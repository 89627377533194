<template>
    <div :class="page_classes">
        <Breadcrumbs v-if="the_page && the_page.breadcrumbs.length > 1" :breadcrumbs="the_page.breadcrumbs" />
        <div v-if="the_page && noTemplate === true">
            <div v-if="hasPermission(['manage:Content'])">
                <div style="position: absolute; right: 0; display:inline-block;">
                    <p class="mr-4 mb-2 body" >status: {{the_page.status}}<span v-if="the_page.status == 'publish'">ed</span></p>
                    <v-btn fab absolute right center class="cms-edit-btn" :to="{name : 'CMSEditor', 'params' : {page_id : the_page.id} }">
                        <v-icon small class="mr-2">fas fa-pencil-alt</v-icon> edit
                    </v-btn>
                </div>
            </div>
            <div v-for="(block, key) in the_page.blocks" :key="key" :class="blockClasses(block, 'my-0')">
                <div :ref="block.Ref" v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
            </div>
        </div>
        <div v-else-if="the_page && is_visible" :is="the_page.template" :content-data="the_page">
            <slot></slot>
            <div v-if="hasPermission(['manage:Content'])">
                <div style="position: absolute; right: 0; display:inline-block;">
                    <p class="mr-4 mb-2 body" >status: {{the_page.status}}<span v-if="the_page.status == 'publish'">ed</span></p>
                    <v-btn fab absolute right center class="cms-edit-btn" :to="{name : 'CMSEditor', 'params' : {page_id : the_page.id} }">
                        <v-icon small class="mr-2">fas fa-pencil-alt</v-icon> edit
                    </v-btn>
                </div>
            </div>
            <div v-for="(block, key) in the_page.blocks" :key="key" :class="blockClasses(block, 'my-0')">
                <div :ref="block.Ref" v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
            </div>
        </div>
        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
        <div v-if="the_page && !is_visible">
            <h1 class="mt-6 ml-6">Page not found</h1>
        </div>
        <div v-else-if="!the_page && !loading">
            <h1 class="mt-6 ml-6">You do not have permission to view this page</h1>
        </div>
    </div>
</template>
<script>
import Default from '@/CMS/views/PageTemplates/default.vue';
import Marketing from '@/CMS/views/PageTemplates/marketing.vue';
import QuilityTech from '@/CMS/views/PageTemplates/quility-tech.vue';
import Resources from '@/CMS/views/PageTemplates/resources.vue';
import Training from '@/CMS/views/PageTemplates/training.vue';
import News from '@/CMS/views/PageTemplates/news.vue';
import Contests from '@/CMS/views/PageTemplates/contests.vue';
import Events from '@/CMS/views/PageTemplates/events.vue';
import Benefits from '@/CMS/views/PageTemplates/benefits.vue';
import Zendesk from '@/CMS/views/PageTemplates/zendesk.vue';
import AgencyBuilding from '@/CMS/views/PageTemplates/agency-building.vue';
import External from '@/CMS/views/PageTemplates/external.vue';
import Carrier from '@/CMS/views/PageTemplates/carrier.vue';
import CarrierResources from '@/CMS/views/PageTemplates/carrier-resources.vue';
import Spacer from '@/CMS/views/ContentBlocks/Spacer.vue';
import CallToAction from '@/CMS/views/ContentBlocks/CallToAction.vue';
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue';
import ZendeskText from '@/CMS/views/ContentBlocks/ZendeskText.vue';
import TwoCol5050 from '@/CMS/views/ContentBlocks/TwoCol5050.vue';
import TwoCol2575 from '@/CMS/views/ContentBlocks/TwoCol2575.vue';
import TwoCol7525 from '@/CMS/views/ContentBlocks/TwoCol7525.vue';
import ThreeCol255025 from '@/CMS/views/ContentBlocks/ThreeCol255025.vue';
import ThreeCol333333 from '@/CMS/views/ContentBlocks/ThreeCol333333.vue';
import FourCol25252525 from '@/CMS/views/ContentBlocks/FourCol25252525.vue';
import CardStyles from '@/CMS/views/ContentBlocks/CardStyles.vue';
import ButtonGroup from '@/CMS/views/ContentBlocks/ButtonGroup.vue';
import CardGroup from '@/CMS/views/ContentBlocks/CardGroup.vue';
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/WistiaVideoGroup.vue';
import IframeGroup from '@/CMS/views/ContentBlocks/IframeGroup.vue';
import Banner from '@/CMS/views/ContentBlocks/Banner.vue';
import ScriptEmbedGroup from '@/CMS/views/ContentBlocks/ScriptEmbedGroup.vue';
import CarrierContactInfo from '@/CMS/views/ContentBlocks/CarrierContactInfo.vue';
import CarrierProducts from '@/CMS/views/ContentBlocks/CarrierProducts.vue';
import CarrierLogo from '@/CMS/views/ContentBlocks/CarrierLogo.vue';
import AcknowledgeCheckBox from '@/CMS/views/ContentBlocks/AcknowledgeCheckBox.vue';
import Accordion from '@/CMS/views/ContentBlocks/Accordion.vue';
import DataTableGroup from '@/CMS/views/ContentBlocks/DataTableGroup.vue';
import SummitCalculator from "@/CMS/views/ContentBlocks/SummitCalculator";
import Leaderboard from '@/CMS/views/ContentBlocks/Leaderboard';
import QForm from '@/CMS/views/ContentBlocks/Form';
import Glossary from '@/CMS/views/ContentBlocks/Glossary';
import Anchor from '@/CMS/views/ContentBlocks/Anchor.vue';
import EditableTable from './ContentBlocks/EditableTable.vue';
import MarketingResource from '@/CMS/views/ContentBlocks/MarketingResource.vue';
import Breadcrumbs from '@/CMS/views/Breadcrumbs.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/store/Models/Page'

export default {
    props: ['page', 'slug', 'noTemplate'],
    data: () => ({
        loaded: false,
    }),
    mounted: function() {
        if (typeof this.$route.params.page_slug != 'undefined') {
            this.loadPage(this.$route.params.page_slug)
        }
        if (typeof this.slug != 'undefined') {
            this.loadPage(this.slug)
        }
    },
    computed: {
        'the_page': function() {
            if (typeof this.page != 'undefined') {
                return this.page
            } else {
                if (typeof this.$route.params.page_slug != 'undefined') {
                    return Page.query().where('slug', this.$route.params.page_slug).first();
                }
                return Page.query().where('slug', this.slug).first();
            }
        },
        'page_classes': function() {
            if (this.the_page == null) { return '' }
            var classes = []
            if (this.the_page.styles && this.the_page.styles.length)
                classes = this.the_page.styles.slice();
            classes.push('cms')
            classes.push('full-height')
            return classes.join(' ')
        },
        'is_visible': function() {
            if (this.the_page && this.the_page.status == 'publish' || this.the_page.status == null || this.hasPermission('manage:Content'))
                return true
            return false
        },
        loading() {
            return this.$store.state.entities.page.loading;
        },
    },
    methods: {
        loadPage(slug) {
            QuilityAPI.getPageWithSlug(slug)
                .then(() => {
                    if (!this.the_page) {
                        this.$forceUpdate();
                        return
                    }
                    if (!this.is_visible) {
                        console.log("not visible")
                        this.$router.push("/404")
                    }
                    this.hashFix()
                    this.$emit("page-loaded", slug)
                })
                .catch((err) => {
                    this.loaded = true
                    console.log(err)
                    if (!this.is_visible) {
                        this.$router.push("/404")
                    }
                })
            //scrolling for on-pag anchor links
        },
        hashFix: function()
                {
                    if(location.hash == "" || location.hash == null) return;

                    this.$vuetify.goTo("#" + location.hash, {
                        duration: 500,
                        easing: "easeOutCubic",
                        offset: 300,
                    });

                }
        // blockClasses(block, other) {
        //     if (typeof block.styles != 'object') {
        //         this.$set(block, 'styles', [])
        //     }
        //     var classes = [
        //         ...block.styles,
        //         block.type,
        //         other
        //     ]
        //     return classes.join(' ')
        // },
        // showBlock: function(block) {
        //     if (block.status === 'draft')
        //         return false

        //     if (block.startDate !== null && block.startDate !== undefined && block.startDate !== "") {
        //         if (!moment(block.startDate).isSameOrBefore(moment(), 'day'))
        //             return false
        //     }

        //     if (block.endDate !== null && block.endDate !== undefined && block.endDate !== "") {
        //         if (!moment(block.endDate).isSameOrAfter(moment(), 'day'))
        //             return false
        //     }
        //     if (block.permissions.division != null && block.permissions.division != 'All' && !this.hasDivision(block.permissions.division)) {
        //         return false
        //     }
        //     if (block.permissions.role != null && block.permissions.role != 'Any' && !this.hasRole(block.permissions.role)) {
        //         return false
        //     }
        //     if (block.permissions.permission != null && block.permissions.permission != 'Any' && !this.hasPermission(block.permissions.permission)) {
        //         return false
        //     }
        //     return true
        // }
    },
    watch: {
        '$route.params.page_slug': function(newV, oldV) {
            if (typeof newV != 'undefined') {
                this.loadPage(newV);
            }
        },
        loaded(val) {
            if (val) {
                if (this.$route.hash) {
                    const element = document.querySelector(this.$route.hash)

                    if (element) {
                        const top = element.getBoundingClientRect().top + window.pageYOffset - 100
                        window.scrollTo({ top })
                    }
                }
            }
        }
    },
    components: {
        Default,
        News,
        Contests,
        Resources,
        Marketing,
        Events,
        Training,
        Benefits,
        Zendesk,
        External,
        Carrier,
        CarrierResources,
        Spacer,
        Anchor,
        CallToAction,
        FullWidthText,
        Glossary,
        TwoCol5050,
        TwoCol2575,
        TwoCol7525,
        ThreeCol333333,
        ThreeCol255025,
        FourCol25252525,
        CardStyles,
        ButtonGroup,
        CardGroup,
        IframeGroup,
        Banner,
        ZendeskText,
        ScriptEmbedGroup,
        AgencyBuilding,
        CarrierContactInfo,
        CarrierProducts,
        CarrierLogo,
        AcknowledgeCheckBox,
        WistiaVideoGroup,
        Accordion,
        DataTableGroup,
        SummitCalculator,
        QuilityTech,
        Leaderboard,
		EditableTable,
		MarketingResource,
        QForm,
        Breadcrumbs,
    },
}
</script>
<style scoped>
.cms-edit-btn {
    width: auto !important;
    padding: 0 15px !important;
}

.cms-edit-btn {
    padding-left: 15px;
}

.v-btn__content a:hover {
    text-decoration: none !important;
}
</style>