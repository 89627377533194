
import QNoticesAcknowledgeButtons from "./QNoticesAcknowledgeButtons.vue";
import { defineComponent } from "vue";
import {
	getUnreadNotices,
	updateFlag,
	Notice,
} from "../../store/API/PopupAcknowledgements";
import { EventBus } from "@/event-bus";
import MessageCard from "../../modules/messages/components/MessageCard.vue";

export default defineComponent({
	components: { QNoticesAcknowledgeButtons, MessageCard },

	data() {
		return {
			unreadNotices: [] as Notice[],
			saving: false,
			doShowAcknowledgedSnackbar: false,
      doShowCloseButton: false,
		};
	},

	mounted() {
		getUnreadNotices().then(({ data }) => {
			this.unreadNotices = data;
			if (this.unreadActive.length === 0) {
				EventBus.$emit('no-unread-notices')
			}
		});
	},

	computed: {
		unreadActive() {
			return this.unreadNotices.filter(
				(notice: Notice) =>
					notice.popup.active &&
					!notice.acknowledged &&
					!notice.snoozed
			);
		},
		doShowDialog() {
			return this.unreadActive.length > 0;
		},
		currentNotice() {
			return this.unreadActive[0];
		},
		currentPopup() {
			return this.currentNotice?.popup;
		},
	},
	watch: {
		unreadActive () {
			if (this.unreadActive.length === 0) {
				EventBus.$emit('no-unread-notices')
			}
		},
	},

	methods: {
    reset() {
      this.doShowCloseButton = false
    },
		async snooze() {
			this.saving = true;
			this.currentNotice.snoozed = (
				await updateFlag(this.currentNotice.id, { snoozed: true })
			).snoozed;
			this.saving = false;
      this.reset()
		},
		async acknowledge() {
			this.saving = true;
			const response = await updateFlag(this.currentNotice.id, {
				acknowledged: true,
			});
			// TODO: Remove it
			if (!response.acknowledged) {
				console.error(
					"Message set as acknowledged, but backend returned false, setting force to true in FE"
				);
			}

		  EventBus.$emit('update:message');

		  this.saving = false;
			this.doShowAcknowledgedSnackbar = true;
		},
    close() {
      this.$set(this.currentNotice, "acknowledged", true);
      this.reset()
    },
    async acknowledgeAndClose() {
      await this.acknowledge()
      this.close()
    },
    async acknowledgeAndShowCloseButton() {
      await this.acknowledge()
      this.doShowCloseButton = true
    },
	},
});
