<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :baseUrl="baseUrl"
        :titlelink="titlelink"
        :has-demo="hasDemo"
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QMyStatsSubmenu",
    props: {
        hasDemo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "My Stats",
            baseUrl: "/my_stats",
            titlelink: [],
        }
    },
    computed: {
        buttons() {
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                return [{
                        label: "Production",
                        to: "/my_stats/scorecard/new_business",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-1"
                    }, {
                        label: "Recruiting",
                        to: "/my_stats/scorecard/recruiting",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-2"
                    }, {
                        label: "Leads",
                        to: "/my_stats/scorecard/leads",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-3"
                    }, {
                        label: "Leaderboards",
                        to: "/my_stats/leaderboards",
                        icon: "fas fa-arrow-alt-circle-right",
                    }, {
                        label: "Event Stats",
                        to: "/my_stats/scorecard/events",
                        icon: "fas fa-arrow-alt-circle-right",
                    }, {
                        label: "HQ Stats",
                        to: "/my_stats/scorecard/hq",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }, {
                        label: "Summit",
                        to: "/my_stats/scorecard/summit",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }
                ]
            }

            if (this.hasRole(['AgencyOwner'])) {
                return [
                    {
                        label: "Production",
                        to: "/my_stats/scorecard/new_business",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-1"
                    }, {
                        label: "Recruiting",
                        to: "/my_stats/scorecard/recruiting",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-monthly-2"
                    }, {
                        label: "Leaderboards",
                        to: "/my_stats/leaderboards",
                        icon: "fas fa-arrow-alt-circle-right",
                    }, {
                        label: "Event Stats",
                        to: "/my_stats/scorecard/events",
                        icon: "fas fa-arrow-alt-circle-right",
                    }, {
                        label: "HQ Stats",
                        to: "/my_stats/scorecard/hq",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }, {
                        label: "Summit",
                        to: "/my_stats/scorecard/summit",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: ""
                    }
                ]
            }

            return [
                {
                    label: "Production",
                    to: "/my_stats/scorecard/new_business",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-monthly-1"
                }, {
                    label: "Recruiting",
                    to: "/my_stats/scorecard/recruiting",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-monthly-2"
                }, {
                    label: "HQ Stats",
                    to: "/my_stats/scorecard/hq",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: ""
                }
            ]
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
