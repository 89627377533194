<template>
    <v-list id="main-menu" dense class="main-navigation-list">
		<div class="mb-auto">
			<component
				v-for="(nav_item, k) in agentMenu " :key="`${k}-${nav_item.label}`"
				:is="nav_item.children.length ? 'MenuGroup' : 'MenuItem'"
				:update="update"
				:label="nav_item.label"
				:icon="nav_item.icon"
				:route="nav_item.route"
				:path="nav_item.to"
				:href="nav_item.href"
				:has-active-child="nav_item.hasActiveChild"
			>
				<template v-if="nav_item.children.length">
					<SubMenuItem
						v-for="(nav_child, k) in nav_item.children" :key="`${k}-${nav_child.label}`"
						:label="nav_child.label"
						:route="nav_child.route"
						:path="nav_child.to"
						:href="nav_child.href"
						small
					/>
				</template>
			</component>
		</div>

		<v-divider v-if="hasDivider" class="main-navigation-list-divider" :class="dividerClass" />

		<div class="flex-shrink-0">
			<component
				v-for="(nav_item, k) in adminMenu " :key="`${k}-${nav_item.label}`"
				:is="nav_item.children.length ? 'MenuGroup' : 'MenuItem'"
				:update="update"
				:label="nav_item.label"
				:icon="nav_item.icon"
				:route="nav_item.route"
				:path="nav_item.to"
				:href="nav_item.href"
				:has-active-child="nav_item.hasActiveChild"
			>
				<template v-if="nav_item.children.length">
					<SubMenuItem
						v-for="(nav_child, k) in nav_item.children" :key="`${k}-${nav_child.label}`"
						:label="nav_child.label"
						:route="nav_child.route"
						:path="nav_child.to"
						:href="nav_child.href"
						small
					/>
				</template>
			</component>
	
			<MenuGroup
				v-if="allowContentManagerMenu"
				:update="update"
				label="Content Editor"
				icon="icon-edit-q"
				:has-active-child="contentManagerMenuHasActive"
			>
				<SubMenuItem
					v-for="item in contentManagerMenu" :key="item.text"
					:label="item.text"
					:route="item.route"
					small
				/>
			</MenuGroup>

			<MenuGroup
				v-if="allowFormManagerMenu"
				:update="update"
				label="Form Management"
				icon="icon-file-check-q"
				:has-active-child="formManagerMenuHasActive"
			>
				<SubMenuItem
					v-for="item in formManagerMenu" :key="item.text"
					:label="item.text"
					:route="item.route"
					small
				/>
			</MenuGroup>
		</div>
    </v-list>
</template>

<script>
import MenuItem from './MenuItem.vue'
import MenuGroup from './MenuGroup.vue'
import SubMenuItem from './SubMenuItem.vue'
import NavigationMixin from '@/mixins/NavigationMixin'

export default {
	components: {
        MenuItem,
        MenuGroup,
        SubMenuItem,
    },
    mixins: [NavigationMixin],
    props: {
        mini: { type: Boolean, default: false },
    },
    data () {
        return {
            update: 0,
			adminMenuLabels: ['Admin', 'Super Admins', 'Summit Admin', 'Carrier Library'],
        }
    },
    computed: {
		agentMenu () {
			const current = this.$route.path
			return this.filteredMenu
				.filter((menu) => !this.adminMenuLabels.some((label) => menu.label === label))
				.map((item) => {
					const hasActiveChild = item.children?.some(({ to }) => {
						const path = to?.includes('#') ? current + this.$route.hash : current
						return to === path
					}) || false
					return { ...item, hasActiveChild }
				})
		},
		adminMenu () {
			const current = this.$route.path
			return this.adminMenuLabels
				.map((label) => this.filteredMenu.find((menu) => menu.label === label))
				.filter(Boolean)
				.map((item) => {
					const hasActiveChild = item.children?.some(({ to }) => {
						const path = to?.includes('#') ? current + this.$route.hash : current
						return to === path
					}) || false
					return { ...item, hasActiveChild }
				})
		},
		allowContentManagerMenu () {
			return this.hasPermission('manage:Content') && !this.isImpersonating
		},
		allowFormManagerMenu () {
			return this.hasPermission('manage:Forms') && !this.isImpersonating
		},
        contentManagerMenu () {
            if (!this.allowContentManagerMenu) { return [] }
            return [
                { route: 'CMS', text: 'Pages' },
                { route: 'HubPages', text: 'Hub Pages' },
                { route: 'Categories', text: 'Categories' },
                { route: { name: 'CMSEditor', params: { page_id: 'new' } }, text: 'New Page' },
                { route: 'MediaLibrary', text: 'Media Library' },
                { route: 'DocumentLibrary', text: 'Document Library' },
                { route: 'CarrierPages', text: 'Carrier Pages' },
                { route: 'CarrierProducts', text: 'Carrier Products' },
                { route: { name: 'NavEditor' }, text: 'Nav Editor' },
                { route: { name: 'EditLevelsOfLeadership' }, text: 'Leadership Profiles' },
                { route: { name: 'EditCorporateStaff' }, text: 'Corporate Profiles' },
                { route: '/page/content-block-examples', text: 'Content Block Examples' },
            ]
        },
        formManagerMenu () {
            if (!this.allowFormManagerMenu) { return [] }
            return [
                { route: 'FML', text: 'Forms' },
                { route: { name: 'FMLEditor', params: { FormID: 'new' } }, text: 'New Form' },
            ]
        },
		contentManagerMenuHasActive () {
			const current = this.$route.name
			return this.contentManagerMenu.some(({ route }) => (
				typeof route === 'object' ? route.name === current : route === current
			))
		},
		formManagerMenuHasActive () {
			const current = this.$route.name
			return this.formManagerMenu.some(({ route }) => (
				typeof route === 'object' ? route.name === current : route === current
			))
		},
		hasDivider () {
			return this.adminMenu.length || this.allowContentManagerMenu || this.allowFormManagerMenu
		},
		dividerClass () {
			return [
				this.mini && 'main-navigation-list-divider--mini'
			]
		},
    },
    methods: {
		/** @public */
        collapse () {
            this.update += 1
        },
    },
}
</script>

<style lang="scss">
.main-navigation-list.v-list {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
</style>
