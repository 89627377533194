<template>
    <v-list-group
		class="navigation-list-item"
		:class="computedClass"
		v-model="isOpen"
		:prepend-icon="icon"
	>
        <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title>{{ label }}</v-list-item-title>
            </v-list-item-content>
        </template>
		<slot></slot>
    </v-list-group>
</template>

<script>
export default {
    name: 'MenuGroup',
	props: {
		update: { type: Number },
		label: { type: String },
		icon: { type: String },
		hasActiveChild: { type: Boolean },
	},
    data: () => ({
        isOpen: false
    }),
	computed: {
		computedClass () {
			return [
				this.hasActiveChild && !this.isOpen && 'navigation-list-item__menu-item-active'
			]
		},
	},
    watch: {
        update () {
            this.isOpen = false
        },
    },
}
</script>
